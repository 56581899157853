import "./styles";
import { Editor } from "@bytemd/react";
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { time } from "../../utils/time";
import { getTerminology, replace, summarize, translate, articleCreate, articleUsers } from "../../request/api";
import {frontmatter, highlight, breaks, zh_Hans, gfm, importHtml} from "./editor"
import EditorSetting from "./EditorSetting";
import CustomCreateModal from "../CustomCreateModal";
import useDebounce from "../../hook/useDebounce";
import { getCache, saveCahce } from "../../utils/cache";
import { addNode, astCodeBlock, getAstNode, getTargetArr } from "../../utils/preview";
import html from 'remark-html';
import { replaceSymbol } from "../../utils/checkSides";
import { useRequest } from 'ahooks';
import { createRoot } from 'react-dom/client';
import { getSelectedText } from "../../utils/getSelectedText";
import { getAllStrIndexes } from "../../utils/getAllStrIndexes";
import { replaceAtIndex } from "../../utils/replaceAtIndex";
import { CustomIcon } from "../CustomIcon";


const remark = require('remark');

function EditorPreview({changeSelectNum, selectObj}, ref) {
  
    const url = process.env.REACT_APP_BASE_URL || (window.location.origin.includes("localhost") ? "http://192.168.1.10:8091" : "http://"+window.location.hostname + ":8091");
    const isStearm = true;
    const { getCurrentTime } = time();
    let [position, setPosition] = useState();
    let [value, setValue] = useState(getCache("right"));
    let [findReplace, setFindReplace] = useState();
    let [replaceObj, setReplaceObj] = useState({
        target: "",
        replacement: ""
    })

    // 查找替换 属性
    let [num, setNum] = useState();
    let [showCreateDialog, setShowCreateDialog] = useState();
    let [showConfirmDialog, setShowConfirmDialog] = useState(false);
    let [translateValue, setTranslateValue] = useState(null);
    let [translateSubtitle, setTranslateSubtitle] = useState(null);
    const [inputTitle, setInputTitle] = useState('');
    const [inputOriginUrl, setInputOriginUrl] = useState('');
    let [count, setCount] = useState();
    const [isNew, setIsNew] = useState();
    const [click, setClick] = useState(false);
    const [selected, setSelected] = useState(false);
    
    const [clear, setClear] = useState(false);
    const [isReplaceAll, setIsReplaceAll] = useState(false);
    const [isReplace, setIsReplace] = useState(false);

    let [isFullChar, setIsFullChar] = useState(eval(localStorage.getItem("translator-replace-isFull")) || false);
    let [isCaseSensitive, setIsCaseSensitive] = useState(eval(localStorage.getItem("translator-replace-isCaseSensitive")) || false);
    
    const [loading, setLoading] = useState();
    const [open, setOpen] = useState();
    const [buttonClick, setButtonClick] = useState(null);
    let [modalPosition, setModalPosition] = useState({});
    let [modalStr, setModalStr] = useState();

    let [mask, setMask] = useState();
    const debouncedSave = useDebounce(() => {
        saveCahce("right", value);
    }, 1000);

    const { run } = useRequest(onSelect, {
        debounceWait: 100,
        manual: true
    });

    const { run: debounce } = useRequest(changeEditor, {
        debounceWait: 100,
        manual: true,
    });

    const { run: debounceKeyDown } = useRequest(keyDownFunc, {
        debounceWait: 100,
        manual: true,
    });

    //  选中后对应另一边编辑器的事件
    const { run: change } = useRequest(changeSelectNum, {
        debounceWait: 100,
        manual: true
    });

    const plugins = useMemo(() => [
        gfm(),frontmatter(),highlight(),breaks(),importHtml(),
        {actions: [{
            title: "设置密钥",
            position: "right",
            icon: '设置', // 16x16 SVG icon
            handler: {
                type: 'action',
                click(ctx, y) {
                    // remember to call `focus` to avoid lost of focus
                    // ctx.editor.focus()
                    mask = true;
                    setMask(mask);
                },
            },
        }]},
        {actions: [{
            title: "总结左侧编辑器原文",
            position: "right",
            icon: '总结', // 16x16 SVG icon
            handler: {
                type: 'action',
                click(ctx, y) {
                    const original = localStorage.getItem("translator-cache-before");
                    if (original) {
                        // downloadMarkdown(cache, `translation-${getCurrentTime()}.md`)
                        setLoading(true);
                        summarize({
                            origin: original,
                            sourceLang: "en",
                            targetLang: "zh-cn"
                        })
                        .then(res => {
                            if (res.code === 0) {                                
                                // 判断当前是否有总结
                                let summarization = res.data.summarization.replace(/\n/g,"\n>")
                                if (value.indexOf("---From ChatGPT") === -1) {
                                    value = ">" + summarization + "\n>---From ChatGPT\n\n" + value;
                                }else{
                                    value = ">" + summarization + "\n>---From ChatGPT\n\n" + value.split("---From ChatGPT\n\n")[1];
                                }
                                setValue(value);
                                setLoading(false);
                            }
                        })
                        .catch(err => {
                            setLoading(false);
                            alert("请求发生未知错误")
                        })
                    }else{
                        alert("请至少先导入一篇文章后再总结！")
                        setLoading(false);
                    }
                },
            }
        }]},
        {actions: [{
            title: "导出文件",
            position: "right",
            icon: '导出', // 16x16 SVG icon
            handler: {
                type: 'action',
                click(ctx, y) {
                    let cache = localStorage.getItem("translator-cache-after");
                    const insert = localStorage.getItem("translator-insert");
                    if (insert && eval(insert)) {
                        let insertHeader = localStorage.getItem("translator-insert-header");
                        let insertFooter = localStorage.getItem("translator-insert-footer");
                        insertHeader = insertHeader ? insertHeader + "\n" : "";
                        insertFooter = insertFooter ? "\n" + insertFooter : "";
                        cache = insertHeader + cache + insertFooter;
                    }
                    if (cache) {
                        downloadMarkdown(cache, `translation-${getCurrentTime()}`)
                    }
                },
            }
        }]},
        {actions: [{
            title: "发布到社区",
            position: "right",
            icon: '发布', // 16x16 SVG icon
            handler: {
                type: 'action',
                async click(ctx, y) {
                    let title_untrans = localStorage.getItem('translator-title-untrans');
                    if(title_untrans){
                        let translated = await translate({
                            origin: title_untrans,
                            translation: '',
                            sourceLang: "en",
                            targetLang: "zh-cn",
                            service: "chatgpt",
                        })
                        localStorage.setItem("translator-title", translated.translation)
                        localStorage.removeItem("translator-title-untrans");
                    }
                    setInputTitle(localStorage.getItem("translator-title"));
                    setInputOriginUrl(localStorage.getItem("translator-originUrl"));
                    setShowCreateDialog(true);
                },
            }
        }]},
        {actions: [{
            title: "替换翻译后的文字",
            position: "right",
            icon: '文字替换', // 16x16 SVG icon
            handler: {
                type: 'action',
                async click(ctx, y) {
                    let cache = localStorage.getItem("translator-cache-after");

                    if (cache) {
                        cache = postReplace(cache)
                        localStorage.setItem("translator-cache-after",cache);
                        setValue(cache);
                    };
                },
            }
        }]},
        {actions: [{
            title: "替换图片来源链接",
            position: "right",
            icon: '换图', // 16x16 SVG icon
            handler: {
                type: 'action',
                async click(ctx, y) {
                    let cache = localStorage.getItem("translator-cache-after");
                    if (cache) {
                        await onlyReplacePictrue(cache);
                    };
                },
            }
        }]},
        {
            viewerEffect: (ctx) => {
              ctx.markdownBody.addEventListener("dblclick", doubleClick)
              return() => {
                ctx.markdownBody.removeEventListener("dblclick", doubleClick)
              }
            },
        },
        {
            editorEffect: (ctx) => {
                ctx.editor.on("cursorActivity", function(instance) {
                    cursorActivity(ctx.editor)
                })
                ctx.editor.getWrapperElement().addEventListener('contextmenu', function(e) {
                    const selectedText = ctx.editor.getSelection();
                    if (selectedText !== '') {
                        e.preventDefault();
                        const x = e.clientX; // 获取鼠标点击位置的X坐标
                        const y = e.clientY; // 获取鼠标点击位置的Y坐标

                        const startLine = ctx.editor.getCursor('from');
                        const endLine = ctx.editor.getCursor('to');

                        run({selectedText, x, y, startLine, endLine,}, ctx.editor)
                    }
                })
                ctx.editor.getWrapperElement().addEventListener("keydown", function(e) {
                    keyDownEvent(e, ctx.editor);
                })
                // 如果打开了`查找替换`
                if (findReplace) {
                    click && debounce(ctx);
                }
                if (loading) {
                    // ctx.editor.setCursor({line: position.line - 1, ch: position.column - 1}) 
                    const cmInstance = ctx.editor;
                    const lastLine = cmInstance.lineCount();
                    cmInstance.setCursor(lastLine)
                }
                if (isReplaceAll && findReplace) {
                    const cmInstance = ctx.editor;
                    var regexFlags = isCaseSensitive ? "g" : "gi";
                    var regexPattern = isFullChar ? "\\b" + replaceObj.target + "\\b" : replaceObj.target;
                    var regex = new RegExp(regexPattern, regexFlags);
                    // 在整个文档中查找选中的文本
                    var doc = cmInstance.getDoc();
                    var docContent = doc.getValue();
                    value = docContent.replace(regex, replaceObj.replacement);
                    setValue(value);
                    setIsReplaceAll(false);
                }
                if (clear) {
                    // 清除所有markText
                    const cmInstance = ctx.editor;
                    const content = cmInstance.getValue();
                    const allMarks = cmInstance.getAllMarks();
                    allMarks.forEach((mark, i) => {
                        mark.clear()
                    })
                    // 将光标移动到所选择的markText结束位置
                    if (!findReplace && allMarks.length !== 0) {
                        const arr = getAllStrIndexes(content, replaceObj.target, {isFullChar, isCaseSensitive});
                        if (arr.length !== 0) {
                            var end = cmInstance.posFromIndex(arr[num-1] + replaceObj.target.length);
                            cmInstance.setCursor(end);
                        }
                    }
                    setClear(false);
                }
                if (position) {          
                    ctx.editor.focus()
                    ctx.editor.setCursor({line: position.line - 1, ch: position.column - 1}) 
                    ctx.editor.scrollIntoView({line: position.line, ch: position.column}, 200)
                    position = null;
                    setPosition(position);
                }
            }
        },
        {
            remark: (p) => p.use(addNode).use(html)
        }
    ])

    function cursorActivity(cmInstance) {
        var selectedText = cmInstance.getSelection();
        if (selectedText === "") {
            const cursorPosition = cmInstance.getCursor();
            removeHtml();
            change(null ,cursorPosition);
        }else{
            const from = cmInstance.getCursor("from");
            const to = cmInstance.getCursor("to");
            const cursorPosition = cmInstance.getCursor();
            const hasSummary = value.indexOf("---From ChatGPT") !== -1
            const summaryLine = hasSummary ? value.split("---From ChatGPT\n")[0].match(/\n/g).length + 2 : 0;
            const startLine = cursorPosition.line === from.line ? to.line - summaryLine : from.line - summaryLine - 1;
            const endLine = cursorPosition.line === from.line ? from.line - summaryLine - 1 : to.line - summaryLine;
            change({startLine, endLine, type: "preview"});
        }
    }

    function onSelect({selectedText, x, y, startLine, endLine}, editor) {
        // 移除前一个
        removeHtml()
        addHtml({selectedText, x, y}, (text)=> {
            const str = text.replace(/\n$/, '');

            editor.replaceRange(text, {line: startLine.line, ch: startLine.ch}, {line: endLine.line, ch: endLine.ch})
            removeHtml();
        });
    }

    function removeHtml() {
        setOpen(false);
        setModalStr(null);
        const doms = document.querySelectorAll(".modal-operate");
        if (doms.length !== 0) {
            doms.forEach(dom => {
                dom.remove();
            })
        }
    }

    function addHtml({selectedText, x, y}, replace) {
        const modal = document.createElement("div");
        const face = (x + 200 + 300) > window.innerWidth;

        modalPosition = { x: face ? x-540 : x+200, y };
        setModalPosition({...modalPosition});
        const root = createRoot(modal);
        root.render(Modal({x: face ? x - 200 : x, y, selectedText}, replace));
        document.querySelector(".custom-editor").appendChild(modal)
    }

    const Modal = ({x, y, selectedText}, replace) => {
        async function translate(selectedText, sourceLang) {
            const res = await toTranslate(selectedText, sourceLang);
            modalStr = res.translation;
            setModalStr(modalStr);
            setButtonClick(() => replace);
        }
        async function stringReplace(selectedText) {
            replace(selectedText
                .replace(/\\=/g, "=")
                .replace(/\\_/g, "_")
                .replace(/\\\*/g, "*")
                .replace(/\\\[/g, "[")
                .replace(/\\\]/g, "]"))
        }
        return (
            <div 
                className="modal-operate" 
                style={{
                    left: x,
                    top: y
                }}
            >
                {/* 正文 */}
                <div className="modal-content">
                    <button onClick={() => translate(selectedText, "zh-cn")}>中译英</button>
                    <button onClick={() => translate(selectedText, "en")}>英译中</button>
                    <button onClick={() => stringReplace(selectedText)}>转义替换</button>
                </div>
            </div>
        )
    }

    async function toTranslate(selectedText, sourceLang) {
        setOpen(true);
        const targetLang = sourceLang === "en" ? "zh-cn" : "en";
        const res = await translate({
            origin: selectedText,
            translation: "",
            sourceLang: sourceLang,
            targetLang: targetLang,
            service: "google"
        })
        return res
    }

  // 双击事件
  function doubleClick(event) {
    //   const select = window.getSelection().toString();

      const dom = document.querySelectorAll(".Editor-preview .bytemd-toolbar-left .bytemd-toolbar-tab")[0];
      dom.click();
      // 提取内容
      const target = event.target;
      const targetTag = target.localName;
      const targetClassName = targetTag === "pre" ? target.children[0].className : target.className;   //  pre特殊处理
      const y = event.offsetY;
      const height = targetTag === "pre" ? target.children[0].offsetHeight : event.target.offsetHeight;
      // 获取当前ast树
      let ast = remark.remark.parse(value);

      // 获取taget类名相同的集合
      let targetArr = getTargetArr(ast, targetClassName)

      // 根据 targetArr length来判断当前点击的是什么
      if (targetArr.length > 1) {
        // 多行文本内的某一个 ===> 可以用column + value来判断
        let selectNode = targetArr.filter(node => targetClassName.split(" ").some(e => e === (node.data.hProperties.className.split(" ")[1] && node?.value)))
        if (selectNode.length === 0) {
            selectNode = targetArr
        }
        const selectBlock = selectNode[0].position;
        position = {
          line: selectBlock.start.line, 
          column: selectBlock.start.column - 1
        };
      }else if (targetArr.length === 1) {
        // 代码块
        const {line, column} = astCodeBlock(targetArr[0], y, height);
        position = { line: line, column: column };
      }else{
        // 代码块中未解析为node的元素
        const node = getAstNode(event.target);
        let targetArr = getTargetArr(ast, node.className);
        let height = node.offsetHeight;
        const {line, column} = astCodeBlock(targetArr[0], y, height);
        position = { line: line, column: column };
      }
      setPosition({...position});
  }

    // 下载
    function downloadMarkdown(content, defaultFileName) {
        let extension = '';

        if (/^\d+\n\d{2}:\d{2}:\d{2},\d{3} --> \d{2}:\d{2}:\d{2},\d{3}/m.test(content)) {
            extension = 'srt';
        } else if (/^\s*\{[\s\S]*\}\s*$/.test(content)) {
            extension = 'json';
        } else if (/^(#{1,6}\s+\w+|\*\s|\d+\.\s|```|---|\>\s|\!\[.*\]\(.*\))/m.test(content)) {
            extension = 'md';
        }

        // 确定文件名和扩展名
        const fileName = `${defaultFileName}${extension ? '.' + extension : ''}`;
        const textBlob = new Blob([content], { type: 'text/plain' });

        const downloadUrl = URL.createObjectURL(textBlob);
        const downloadLink = document.createElement('a');
        downloadLink.href = downloadUrl;
        downloadLink.download = fileName;

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        
        URL.revokeObjectURL(downloadUrl);
    }

    function getGlossary(value, type, terminology) {
        // let str = value;
        const local = localStorage.getItem("gpt-glossary-list");
        const isOn = localStorage.getItem("gpt-glossary-status");
        if (isOn === "true" && local && JSON.parse(local).length !== 0) {
            const items = JSON.parse(local);
            // str = replaceSymbol(items, str, type);
            let newArray = items.map(obj => {
                let { id, ...newObj } = obj;
                return newObj;
            });
            return JSON.stringify(newArray);
        }
        // str = replaceSymbol(terminology, str, type);
        // return str;
        return [];
    }

    // 图片替换
    async function pictureReplace(value, isOnly) {

        let str = value;
        let images = [];
        const isOn = localStorage.getItem("gpt-picture-status");
        if (isOn === "true" || isOnly) {
            var regex = /\!\[[^\]]*\]\((.*?)\)/g;
            var matches = str.match(regex);
            if (matches) {                
                let promises = matches.map(match => {
                    let regex = /!\[(.*?)\]\((.*?)\)/;
                    let arr = regex.exec(match);
                    // 将图片替换为本域图片
                    if (arr[2].indexOf("https://img.learnblockchain.cn") === -1) {
                        // 处理图片
                        const url = arr[2].replace(/\\/g, "");
                        return replace({url})
                            .then(res => {
                                if (res.code === 0) {
                                    // str = str.replace(res.data.url, res.data.newUrl);
                                    images.push({
                                        url: arr[2],
                                        newUrl: res.data.newUrl
                                    })
                                }
                            })
                            .catch(err => {
                                console.log(err);
                            });
                    }
                    return Promise.resolve(null); // 对于不需要处理的 match，返回一个已解析的 Promise
                });
                
                await Promise.all(promises)
                // for (let i = 0; i < matches.length; i++) {
                //     const match = matches[i];
                //     let regex = /!\[(.*?)\]\((.*?)\)/;
                //     let arr = regex.exec(match);
                //     // 将图片替换为本域图片
                //     if (arr[2].indexOf("https://img.learnblockchain.cn") === -1) {
                //         // 处理图片
                //         const url = arr[2].replace(/\\/g, "");
                //         await replace({url})
                //         .then(res => {
                //             if (res.code === 0) {
                //                 // str = str.replace(res.data.url, res.data.newUrl);
                //                 images.push({
                //                     url: arr[2],
                //                     newUrl: res.data.newUrl
                //                 })
                //             }
                //         })
                //         .catch(err => {
                //             console.log(err);
                //         })
                //     }
                // }
            }
        }
        return {str, images}
    }

    // 只替换图片
    async function onlyReplacePictrue(value) {
        setLoading(true);
        const { str, images } = await pictureReplace(value, true)
        let newStr = str;
        images.forEach(e => {
            newStr = newStr.replace(e.url, e.newUrl);
        })
        changeStr(newStr)
        setLoading(false);
    }

    // 后置替换
    function postReplace(res) {
        const replaceIsOn = eval(localStorage.getItem("gpt-replace-status")) || false;
        const arr = JSON.parse(localStorage.getItem("gpt-replace-list")) || [];
        let newStr = res;
        if (replaceIsOn) {
            arr.forEach(e => {
                const regex = new RegExp(e.source, 'g');
                newStr = newStr.replace(regex, e.target);
            })
            return newStr
        }
        return res
    }

    function changeStr(res) {
        value = res;
        setValue(value);
        if (findReplace) {
            const oldNum = num;
            const oldTotal = count;
            calculateTotal();
            if (oldTotal > count) {
                setNum(oldNum - 1 === 0 ? oldNum : oldNum - 1);
            }else if (oldTotal < count){
                setNum(oldNum + 1 > count ? oldNum : oldNum + 1);
            }else{
                setNum(oldNum)
            }
            if (count === 0) {
                setNum(0);
            }
            setClick(true);
        }
    }

    // 翻译
    async function changeValue(value,subtitle) {
        setValue("");
        setLoading(true);
        setTranslateValue(value)
        setTranslateSubtitle(subtitle)

        let title_untrans = localStorage.getItem('translator-title-untrans');
        if(title_untrans){
            let translated = await translate({
                origin: title_untrans,
                translation: '',
                sourceLang: "en",
                targetLang: "zh-cn",
                service: "chatgpt",
            })
            localStorage.setItem("translator-title", translated.translation)
            localStorage.removeItem("translator-title-untrans");
        }

        // 获取 terminology.md 术语表
        // const mdContent = await getTerminology()
        // let terminology = [];
        // if (mdContent.code === 0) {            
        //     terminology = mdContent.data;
        //     terminology.forEach((e,i) => {
        //         e.id= `$b${i + 1}_`
        //     })
        // }
        // 分离短语
        // terminology = terminology.sort((a, b) => {
        //     let aHasSpace = a.source.includes(" ");
        //     let bHasSpace = b.source.includes(" ");
        
        //     return bHasSpace - aHasSpace;
        // });

        // 翻译前把术语词汇给替换了
        // let str = getGlossary(value, "set", terminology);
        const terminology = getGlossary();
        let str = value;

        // 是否流式翻译 ===>
        if (isStearm) {
            // 替换图片
            let newStr = "";
            let flag = false;
            let images;
            let isOk = false;
            new Promise((resolve, reject) => {            
                // 图片
                pictureReplace(str)
                .then(({images: imgList}) => {
                    images = imgList;
                    isOk && resolve(value);
                })
                // 流式
                fetch(`${url}/translator/stream`, {
                    method: 'POST',
                    headers: {
                    'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        origin: str,
                        translation: "",
                        sourceLang: "en",
                        targetLang: "zh-cn",
                        terminology,
                        subtitle,
                        service: localStorage.getItem("translator-service")
                    })
                })
                .then(res => {
                    const reader = res.body.getReader();
                    const decoder = new TextDecoder('utf-8');

                    if (res.status !== 201) {
                        reject(res.statusText)
                        return
                    }
                
                    reader.read()
                    .then(function processText({ done, value }) {

                        if (decoder.decode(value) === "\n====REPEATED_CONTENT_DECTECED====\n") {
                            setLoading(false);
                            setShowConfirmDialog(true)
                            return
                        }

                        if(decoder.decode(value) == '' || decoder.decode(value) == null){
                            return;
                        }

                        if (done || flag) {
                            console.log('Stream complete');
                            const res = decoder.decode(value)
                            if(res){
                                setValue(res);
                                isOk = true;
                                images && resolve(res);
                            }
                            return;
                        }

                        if (decoder.decode(value) === "\n====final translation====\n") {
                            flag = true;
                            return reader.read().then(processText);
                        }

                        let resultStr = decoder.decode(value)

                        if (resultStr.includes("====final translation====")) {
                            resultStr = resultStr.replace("\n====final translation====\n", "");
                            newStr = resultStr;
                            setValue(resultStr);
                            isOk = true;
                            images && resolve(resultStr);
                        } else {
                            newStr += decoder.decode(value);
                            setValue(newStr);
                        }

                        return reader.read().then(processText);
                    });
                })
                .catch(err => {
                    setLoading(false);
                    console.error(err)
                    reject();
                });
            })
            .then(res => {
                let result = res;
                // 替换图片
                images.forEach(e => {
                    result = result.replace(e.url, e.newUrl);
                })
                // 翻译后置替换
                result = postReplace(result);
                // 处理标题中的加粗符号
                const processedResult = result.split("\n").map(e => {
                    if (e.startsWith("#")) {
                        return e.replaceAll("**","");
                    }
                    return e
                }).join("\n");
                changeStr(processedResult)
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                changeStr("");
                setLoading(false);
            })
        }else{
            // 替换图片
            let funcR = pictureReplace(str);
            let funcT = translate({
                origin: str,
                translation: "",
                sourceLang: "en",
                targetLang: "zh-cn",
                terminology,
                service: localStorage.getItem("translator-service")
            })
            let [{images}, res] = await Promise.all([funcR, funcT]);
            let result = res.translation;
            // 替换图片
            images.forEach(e => {
                result = result.replace(e.url, e.newUrl);
            })
            // 翻译后置替换
            result = postReplace(result);
            // 处理标题中的加粗符号
            const processedResult = result.split("\n").map(e => {
                if (e.startsWith("#")) {
                    return e.replaceAll("**","");
                }
                return e
            }).join("\n");
            changeStr(processedResult)
            setLoading(false);
        }
    }

    function closeMask(params) {
        setMask(false);
    }

    // 修改查找替换obj
    function changeReplaceObj(key, value) {
        replaceObj[key] = value;
        setReplaceObj({...replaceObj});
        if (key === "target") {
            setClick(true);
        }
    }

    // 查找并替换内容
    function replaceTextAll() {
        setIsReplaceAll(true);
    }

    function replaceText() {
        setIsReplace(true);
    }

    // 计算当前`查找替换`内的目标文本
    function calculateTotal(params) {
        const {target, replacement} = replaceObj;
        let newNum;
        if (target === "") {
            count = 0;
            newNum = 0;
            setClear(true);
        }else{
            const arr = getAllStrIndexes(value, target, {isFullChar, isCaseSensitive});
            count = arr.length;
            newNum = arr.length === 0 ? 0 : 1;
        }
        // 写入当前target查找到了几个
        setCount(count);
        setNum(newNum);
    }

    function keyDownFunc(cmInstance) {
        const selectText = getSelectedText();
        changeReplaceObj("target", selectText);
        findReplace = selectText ? true : !findReplace;
        setFindReplace(findReplace);
        if (findReplace) {
            setClick(true);
            setIsNew(true);
        }
    }

    function keyDownEvent(event, cmInstance) {
        if (event.key === "Escape" && findReplace) {
            setFindReplace(false);
        }
        if (event.metaKey && event.key === 'f') {
          // 用户按下了 ctrl + f
          // 在这里执行你的代码
          // 打开文本替换弹窗
          debounceKeyDown(cmInstance)
          event.preventDefault(); // 阻止默认行为，例如浏览器的查找功能
        }
    }

    // 查找上一个 || 下一个
    function operateSelectNum(type) {
        let selectNum = num;
        if (type === "add") {
            if (num === count) {
                selectNum = 1;
            }else{
                selectNum += 1;
            }
        }
        if (type === "reduce") {
            if (num === 1) {
                selectNum = count;
            }else{
                selectNum -= 1;
            }
        }
        setNum(count === 0 ? 0 : selectNum);
        setClick(true);
        setSelected(true);
    }

    function retranslation(){
        setShowConfirmDialog(false)
        changeValue(translateValue,translateSubtitle)
    }

    // 更新编辑器
    function changeEditor(ctx) {
        setClick(false);
        const cmInstance = ctx.editor;
        const content = cmInstance.getValue();
        const index = content.indexOf(replaceObj.target);

        // 清除所有markText
        cmInstance.getAllMarks().forEach(mark => {
            mark.clear()
        })

        // 是否有选中内容
        if (replaceObj.target === "" || index === -1) {
            // 获取当前选中的是第几位
            return
        }

        // 如果当前有选中内容，查找它是第几个
        const selectedText = cmInstance.getSelection();
        if (selectedText !== "" && isNew) {
            // 构建正则表达式
            var regexFlags = isCaseSensitive ? "g" : "gi";
            var regexPattern = isFullChar ? "\\b" + selectedText + "\\b" : selectedText;
            var regex = new RegExp(regexPattern, regexFlags);

            // 在整个文档中查找选中的文本
            var doc = cmInstance.getDoc();
            var docContent = doc.getValue();
            var matches = [...docContent.matchAll(regex)];
            // 获取当前选中文本的索引
            var cursorIndex = doc.indexFromPos(cmInstance.getCursor("start"));
            var selectedOccurrenceIndex = matches.findIndex(match => match.index === cursorIndex);
            num = selectedOccurrenceIndex + 1;
            setNum(num);
            setIsNew(false);
        }

        // 使用markText高亮找到的字符串
        const arr = getAllStrIndexes(content, replaceObj.target, {isFullChar, isCaseSensitive});
        
        arr.forEach((index, i) => {
            var start = cmInstance.posFromIndex(index);
            var end = cmInstance.posFromIndex(index + replaceObj.target.length);
            let className = (i === num - 1) ? "custom-highlight-select" : 'custom-highlight';
            cmInstance.markText(start, end, {className: className});
            if (selected && (num - 1 === i)) {
                cmInstance.setSelection(start, end);
                setSelected(false);
            }
        })
    }

    useImperativeHandle(ref, () => ({
        changeValue,
        loading,
        onlyReplacePictrue
    }))

    useEffect(() => {
        calculateTotal();
    },[replaceObj.target])

    useEffect(() => {
        debouncedSave()
    },[value])

    useEffect(() => {
        findReplace !== undefined && setClear(true);
    },[findReplace])

    return (
        <div className="Editor Editor-preview">
            {
                findReplace &&
                <div className="find-replace">
                    <div className="flex">
                        <input type="text" placeholder="查找" value={replaceObj.target} onChange={(e) => changeReplaceObj("target", e.target.value)} />
                        <button 
                            title="区分大小写" 
                            className={isCaseSensitive ? "active" : ""}
                            onClick={() => {
                                isCaseSensitive = !isCaseSensitive;
                                setIsCaseSensitive(isCaseSensitive);
                                localStorage.setItem("translator-replace-isCaseSensitive", isCaseSensitive)
                                setClick(true);     //  更新编辑器
                                calculateTotal();   //  更新个数
                            }}
                        >{CustomIcon("text-size")}</button>
                        <button 
                            title="全字匹配" 
                            className={isFullChar ? "active" : ""}
                            onClick={() => {
                                isFullChar = !isFullChar;
                                setIsFullChar(isFullChar);
                                localStorage.setItem("translator-replace-isFull", isFullChar)
                                setClick(true);     //  更新编辑器
                                calculateTotal();   //  更新个数
                            }}
                        >{CustomIcon("word-match")}</button>
                        <div className="total" style={{color: "#fff"}}>
                            第 {num || 0} 项，共 {count} 项
                        </div>
                        <button onClick={() => operateSelectNum("reduce")}>⬆️</button>
                        <button onClick={() => operateSelectNum("add")}>⬇️</button>
                        <button onClick={() => {setFindReplace(false)}}>❌</button>
                    </div>
                    <div className="flex">
                        <input type="text" placeholder="替换" value={replaceObj.replacement} onChange={(e) => changeReplaceObj("replacement", e.target.value)} />
                        <button title="替换" onClick={replaceText}>{CustomIcon("replace")}</button>
                        <button title="全部替换" onClick={replaceTextAll}>{CustomIcon("replace-all")}</button>
                    </div>
                </div>
            }
            <Editor
              mode="tab"
              locale={zh_Hans}
              value={value}
              plugins={plugins}
              onChange={(v) => changeStr(v)}
            />
            {
                loading &&
                <div className="loading">
                    <div className="loading-box"></div>
                </div>
            }
            {showCreateDialog && (
                <CustomCreateModal
                    inputTitle={inputTitle}
                    setInputTitle={setInputTitle}
                    inputOriginUrl={inputOriginUrl}
                    setInputOriginUrl={setInputOriginUrl}
                    setShowCreateDialog={setShowCreateDialog}
                    value={value}
                    createType={2}
                />
            )}
            {showConfirmDialog && (
                <div className="mask">
                    <div style={{width: '380px'}} className="mask-container">
                        <div style={{padding: '30px 0',minHeight: 'auto'}} className="mask-content">
                            <div>检测到重复内容，是否重新翻译</div>
                            <div style={{marginTop: '20px',display: 'flex',justifyContent: 'center',gap: '50px'}}>
                                <button className="linkImport" onClick={() => setShowConfirmDialog(false)}>取消</button>
                                <button className="linkImport" onClick={() => retranslation()}>确认</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <EditorSetting
                mask={mask}
                closeMask={closeMask}
            />

            {
                open &&
                <div className="modal" style={{
                    left: modalPosition?.x, top: modalPosition?.y
                }}>
                    <div className="modal-replace">
                        <div>
                            {modalStr || <div className="loading-box"></div>}
                        </div>
                        <div className="btns">
                            <button className="normal-btn" onClick={() => removeHtml()}>取消</button>
                            <button className="primary-btn normal-btn replace-btn" onClick={() => buttonClick(modalStr)}>替换</button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default forwardRef(EditorPreview)